import React, { useEffect, useRef } from "react";

const Alert: React.FC = () => {
  const ref = useRef<HTMLDialogElement>(null);

  const cookies = Object.fromEntries(
    document.cookie.split(";").map((line) => line.trim().split("="))
  );

  const canNotShow = !(
    cookies.alert_title &&
    cookies.alert_title.length > 0 &&
    cookies.alert_content &&
    cookies.alert_content.length > 0
  );

  useEffect(() => {
    if (canNotShow) {
      return;
    }

    setTimeout(() => {
      if (ref.current) {
        ref.current.showModal();
      }

      document.cookie = "alert_content=;";
      document.cookie = "alert_title=;";
    });
  }, []);

  if (canNotShow) {
    return null;
  }

  return (
    <dialog ref={ref} className={"modal modal-bottom lg:modal-middle"}>
      <div className={"modal-box"}>
        <form method="dialog">
          <h1 className={"font-semibold"}>{cookies.alert_title}</h1>
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 rtl:left-2 rtl:right-[unset]">
            ✕
          </button>
        </form>

        <p className={"font-medium break-words my-5"}>
          {cookies.alert_content}
        </p>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default Alert;
